import { Canvas, Textbox } from 'fabric'
import { createSignal, onMount } from 'solid-js'
import styles from '~/components/debug-canvas/debug-canvas.module.scss'

const DebugCanvas = () => {
  const [canvas, setCanvas] = createSignal<Canvas>()
  let canvasRef!: HTMLCanvasElement

  onMount(async () => {
    const canvasObject = new Canvas(canvasRef, {
      width: 512,
      height: 512
    })
    setCanvas(canvasObject)

    const text = new Textbox('DebugText', {
      selectable: true,
      top: 32,
      left: 32,
      fontFamily: 'Arial'
    })
    const textStroke1 = await text.clone()
    textStroke1.set({
      selectable: false,
      fill: 'red',
      stroke: 'red',
      strokeWidth: 9,
      strokeLineCap: 'round',
      strokeLineJoin: 'round',
    })
    const textStroke2 = await text.clone()
    textStroke2.set({
      selectable: false,
      fill: 'blue',
      stroke: 'blue',
      strokeWidth: 6,
      strokeLineCap: 'round',
      strokeLineJoin: 'round',
    })

    canvas()?.add(text, textStroke1, textStroke2)
    canvas()?.setActiveObject(text)
  })

  const onDelete = () => {
    const canvasObject = canvas()
    const objects = canvas()?.getObjects()
    if (canvasObject && objects){
      console.log(objects)
      console.log('removed', canvasObject.remove(...objects))
    }
  }

  return (
    <div class={styles.container}>
      <div class={styles.debugCanvas}>
        <canvas ref={canvasRef} />
      </div>
      <aside>
        <button onClick={onDelete}>Delete text</button>
      </aside>
    </div>
  )
}

export default DebugCanvas